<script>
import { fade } from 'svelte/transition'
import { checkout } from '../stores.js'
import LoremIpsum from '../components/LoremIpsum.svelte'
import Footer from '../components/Footer.svelte'

$checkout = false
</script>

<div class="page" transition:fade>
  <div class="container mt-8 px-2">

    <LoremIpsum />

  </div>
  <Footer />
</div>
