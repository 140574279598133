<script>
export let quantity = 0
export let uom

const add = () => {
  quantity++
}

const remove = () => {
  if (quantity > 0) {
    quantity--
  }
}

</script>

<style>
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>

<div class="flex flex-row items-center">
  <button
    class="btn dark w-10 px-0"
    on:click={remove}
    ><span class="material-icons text-xl">remove</span></button>
  <input
    type="number"
    bind:value={quantity}
    disabled={uom === 'EA'}
    class="w-16 h-12 appearance-none bg-transparent text-center text-2xl focus:outline-none border-2 border-transparent focus:border-brand-red rounded"
    >
  <!--<div class="h-12 w-12 text-2xl flex items-center justify-center">{quantity}</div>-->
  <button
    class="btn dark w-10 px-0"
    on:click={add}
    ><span class="material-icons text-xl">add</span></button>
</div>
