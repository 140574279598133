<script>
import { fade, fly } from 'svelte/transition'
import { isDesktop, pages } from '../stores.js'

const links = [
  { href: '/', label: 'BOOK' },
  { href: '/features', label: 'FEATURES' },
  { href: '/about', label: 'ABOUT' },
  { href: '/feedback', label: 'FEEDBACK' }
]

let menuActive = false
const toggleMenu = () => {
  menuActive = !menuActive
}

let menuLabel = 'BOOK'
let found
$: {
  found = links.find(i => $pages[0] === i.href)
  menuLabel = found ? found.label : ($pages[0] === '/booking' ? 'MY BOOKING' : 'BOOK')
}

</script>

<!-- Mobile menu -->
{#if !$isDesktop && menuActive }
<div class="absolute inset-0 bg-black opacity-50" transition:fade on:click={toggleMenu}></div>
<nav class="absolute top-0 inset-x-0 flex flex-col shadow rounded-b-lg mt-16" transition:fly="{{ y: -200 }}" on:click={toggleMenu}>
  <ul>
    {#each links as link}
    <li class="h-16 w-full border border-gray-200 bg-white hover:bg-gray-300 text-gray-900" class:font-bold="{$pages[0] === link.href}">
      <a class="h-full w-full flex items-center justify-center text-sm" href={link.href}>{link.label}</a>
    </li>
    {/each}
    <li class="h-16 w-full border border-gray-200 bg-white hover:bg-gray-300 text-gray-900" class:font-bold="{$pages[0] === '/booking'}">
      <a class="h-full w-full flex items-center justify-center text-sm" href="/booking">MY BOOKING</a>
    </li>
  </ul>
</nav>
{/if}

<header class="absolute top-0 inset-x-0 h-16 bg-white shadow-md">
  <div class="container mx-auto h-full flex flex-row items-center">
    <a class="flex flex-row items-center" href="/">
      <img class="w-12 h-12" src="/logo_128x128.jpg" alt="HireHammer logo" />
      <h1 class="font-serif text-xl text-gray-900 tracking-wider"><span class="text-brand-red"><span class="text-2xl">H</span>IRE</span><span class="text-2xl">H</span>AMMER</h1>
    </a>
    <div class="flex-1"></div>

		<nav class="hidden md:flex flex-row items-center">
      <div class="flex flex-col">
        <ul class="flex flex-row">
          {#each links as link}
          <li class="px-2 border-b-2 {$pages[0] === link.href ? 'border-brand-red' : 'border-transparent'}">
            <a class="text-sm text-gray-900 hover:text-red-800" href={link.href}>{link.label}</a>
          </li>
          {/each}
        </ul>
      </div>
      <a href="/booking" class="btn mx-4">MY BOOKING</a>
		</nav>

    <button class="btn mr-2 md:hidden" on:click={toggleMenu}>
      {menuLabel}
      <span class="material-icons text-2xl ml-2">{menuActive ? 'cancel' : 'menu'}</span>
    </button>

  </div>
</header>
