import { readable, writable, derived } from 'svelte/store'
import { throttle } from '@github/mini-throttle'

export const debug = writable('debug')

export const pages = writable([''])

export const checkout = writable(false)

export const width = readable(null, (set) => {
  const check = throttle(() => set(window.innerWidth), 75)
  window.addEventListener('resize', check)
  check()
})

export const isDesktop = derived(width, $width => $width > 767)

export const items = writable([])

// TODO: Sync orders with local storage
export const orders = writable([
  // { service: 'electrical', quantity: 1, amount: 35, id: 0, title: 'Light Point (Casing)', desc: 'To supply and install', type: 'HDB', unitPrice: 35 },
])

export const types = writable({})
