<script>
import { orders } from '../stores.js'

let electrical
$: electrical = $orders.filter(line => line.service === 'electrical')

</script>

{#if electrical.length > 0}
<div class="w-full text-xs font-bold text-gray-500 mb-4">ELECTRICAL WORKS</div>

<table>
  <thead>
    <tr>
      <th>DESCRIPTION</th>
      <th>TYPE</th>
      <th>UNIT PRICE</th>
      <th>QUANTITY</th>
      <th>AMOUNT</th>
    </tr>
  </thead>
  <tbody>
    {#each electrical as item}
    <tr>
      <td>{item.title} - {item.desc} </td>
      <td>{item.type}</td>
      <td>{item.unitPrice}</td>
      <td>{item.quantity}</td>
      <td>{item.amount}</td>
    </tr>
    {/each}
  </tbody>
</table>



{/if}

