<script context="module">
let lastPos = 0
</script>

<script>
import { throttle } from '@github/mini-throttle'
import { onMount } from 'svelte'
import { fade, fly } from 'svelte/transition'
import { pages, checkout, width } from '../stores.js'
import Footer from '../components/Footer.svelte'
import Image from '../components/Image.svelte'

const services = [
  { src: 'vinyl', alt: 'vinyl flooring', title: 'Vinyl Works', href: '/app/vinyl' },
  { src: 'carpentry', alt: 'carpentry wooden cabinet', title: 'Carpentry', href: '#' },
  { src: 'tiling', alt: 'colourful tiles', title: 'Tiling & Masonry', href: '/app/tiling' },
  { src: 'electrical', alt: 'circuit breaker', title: 'Electrical', href: '/app/electrical' },
  { src: 'plumbing', alt: 'tap water flowing into glass', title: 'Plumbing', href: '#' },
  { src: 'painting', alt: 'painting a wall', title: 'Painting', href: '/app/painting' },
  { src: 'doors', alt: 'white closed door', title: 'Doors', href: '#' },
  { src: 'windows', alt: 'reflective windows', title: 'Windows & Grilles', href: '#' },
  { src: 'mirrors', alt: 'mirror on haystack', title: 'Mirrors & Glass', href: '#' },
  { src: 'hacking', alt: 'hacking and haulage', title: 'Hacking & Haulage', href: '/app/hacking' },
  { src: 'surfaces', alt: 'items on a table surface', title: 'Surface Works', href: '#' },
  { src: 'ceilings', alt: 'light fixtures', title: 'False Ceilings', href: '#' }
]

// Enable sliding checkbar
let dom
const handler = throttle(() => {
  const pos = dom.scrollTop
  $checkout = pos > 64 ? pos > lastPos : false
  lastPos = pos
}, 50)

// Custom transitions
const computeTransition = (node, params) => {
  if ($pages[0].startsWith('/app/') || services.filter(i => i.href === $pages[1]).length) {
    return fly(node, { x: -$width, opacity: 1 })
  }
  return fade(node, params)
}

// Restore last scroll position
onMount(() => {
  dom.scroll(0, lastPos)
})

let cnt = 0
const handleTest = () => {
  toast.push(`test ${cnt++}`)
}
</script>

<style>
.hero {
  height: 32rem;
}
.tile {
  width: 47%;
  padding-bottom: 4rem;
}
@media (min-width: 768px) {
  .tile {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .tile {
    width: 23%;
  }
}
</style>

<div class="page" in:computeTransition out:computeTransition bind:this={dom} on:scroll={handler}>

  <div class="hero relative w-full mb-12">
    <Image
      src="https://source.unsplash.com/X7wkEZ5R384/1920x600"
      srcset="https://source.unsplash.com/X7wkEZ5R384/1920x600 1920w,https://source.unsplash.com/X7wkEZ5R384/1024x600 1024w,https://source.unsplash.com/X7wkEZ5R384/768x600 768w"
      placeholder="https://source.unsplash.com/X7wkEZ5R384/20x20"
      />
    <div class="absolute inset-0 flex flex-col items-center justify-center text-white text-center">
      <h1 class="font-serif text-3xl md:text-4xl mb-8 md:mb-12">BOOK A CONTRACTOR, INSTANTLY</h1>
      <p class="text-lg md:text-xl mx-4 mb-12 md:mb-16">At HireHammer, we offer real-time transparent pricing with no hidden costs.</p>
      <a class="btn mb-4" href="/features" on:click|preventDefault={handleTest}>HOW IT WORKS</a>
    </div>
  </div>

  <div class="container px-2">

    <!-- Services -->
    <div class="flex flex-row flex-wrap justify-around">
      {#each services as { src, alt, title, href }}
      <a class="tile relative group cursor-pointer bg-white shadow hover:shadow-xl rounded-sm mb-12" {href}>
        <div class="relative w-full pb-full">
          <div class="absolute w-full h-full">
            <Image src="/images/shop-{src}_480x480.jpg" placeholder="/images/shop-{src}_24x24.jpg" {alt} />
          </div>
        </div>
        <div class="absolute bottom-0 w-full h-16 flex flex-row items-center">
          <div class="flex-1 flex flex-col ml-4">
            <h2 class="font-serif">{title}</h2>
          </div>
          <div class="material-icons text-4xl text-gray-500 group-hover:text-gray-700">keyboard_arrow_right</div>
        </div>
        <div class="absolute inset-0 bg-transparent hover:bg-brand-red opacity-10"></div>
      </a>
      {/each}
    </div>

  </div>
  <Footer />
</div>
