<script>
import Image from '../components/Image.svelte'

export let item
export let tags

</script>

<a
  class="group w-full max-w-screen-md h-20 bg-white hover:bg-red-100 cursor-pointer flex flex-row shadow hover:shadow-lg mb-2"
  href="/app/{item.cat}/{item.handle}"
  >
  <div class="w-20 h-20 group-hover:opacity-75">
    <Image src="https://source.unsplash.com/random/80x80" />
  </div>
  <div class="flex-1 flex-col px-2 py-2 overflow-hidden">
      <h4 class="text-sm text-gray-800">
        {item.title}
        {#each tags as tag}
          {#if item[tag]}
          <span class="text-xxs bg-gray-300 text-gray-700 border border-gray-400 rounded-full tracking-wide px-2 mr-1 whitespace-pre">{item[tag]}</span>
          {/if}
        {/each}
      </h4>
    <div class="clamp-2 text-sm text-gray-500">{item.shortDesc}</div>
  </div>
  <div class="w-12 flex flex-col items-center pt-2">
    <div class="text-base text-gray-800">${item.unitPrice % 1 === 0 ? item.unitPrice : item.unitPrice.toFixed(2)}</div>
    <div class="material-icons group-hover:text-red-800">keyboard_arrow_right</div>
    <div class="text-xs text-gray-500 tracking-tighter">{item.uom}</div>
  </div>
</a>
