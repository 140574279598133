<script context="module">
let lastPos = 0
</script>

<script>
import { fly, fade } from 'svelte/transition'
import { width, isDesktop, pages, items as itemStore, orders, checkout, types } from '../stores.js'
import { onMount, onDestroy } from 'svelte'
import ItemCard from '../components/ItemCard.svelte'
import TypeSelector from '../components/TypeSelector.svelte'
import Image from '../components/Image.svelte'

export let items
export let cat
export let title
export let filters
export let tags

/*
const getDistinct = key => {
  const distint = [... new Set(list.map(i => i[key]))]
  const values = distint.reduce((a, c) => c + ',' + a, '')
}
*/

let list
$: list = items.filter(i => {
  for (let a = 0; a < filters.length; a++) {
    //if ($types[filters[a].key] && i[filters[a].key] && $types[filters[a].key] !== i[filters[a].key]) {
    if ($types[filters[a].key] && $types[filters[a].key] !== i[filters[a].key]) {
      return false
    }
  }
  return true
})

// If `values` key is not passed into filters, try to autodetect from item list
for (let a = 0; a < filters.length; a++) {
  if (!filters[a].values) {
    const keys = items.map(i => i[filters[a].key])
    filters[a].values = [...new Set(keys)]
  }
}

//Custom transitions
const inTransition = (node, params) => {
  if ($pages[1] === '/') {
    return fly(node, { x: $width, opacity: 1 })
  }
  if ($pages[1].startsWith(`/app/${cat}/`)) {
    return fly(node, { x: -$width, opacity: 1 })
  }
  return fade(node, params)
}
const outTransition = (node, params) => {
  if ($pages[0] === '/') {
    return fly(node, { x: $width, opacity: 1 })
  }
  if ($pages[0].startsWith(`/app/${cat}/`)) {
    return fly(node, { x: -$width, opacity: 1 })
  }
  return fade(node, params)
}

let filtersHeight
let dom
onMount(() => {
  // Restore last scroll position
  dom.scroll(0, lastPos)
  $checkout = true
})
onDestroy(() => {
  // Save scroll position only if next page is item details, else reset to zero
  lastPos = $pages[0].startsWith(`/app/${cat}/`) ? dom.scrollTop : 0
})

</script>

<style>
@media (min-width: 768px) {
  .housing {
    width: 35%;
  }
  .wrap {
    margin-left: 37%;
  }
}
</style>

<div class="page" in:inTransition out:outTransition bind:this={dom}>

  <div class="container px-4 mb-24">
    <a href='/' class="w-24 h-12 flex items-center text-sm text-gray-900 hover:text-red-800"><span class="material-icons text-3xl">keyboard_arrow_left</span> BACK</a>
    <h1 class="text-center font-serif text-xl mt-2 mb-8">{title}</h1>
    <div class="relative">

      <div class="housing md:sticky md:top-0 md:inline-block" bind:clientHeight={filtersHeight}>
        <div class="flex flex-col">
          {#each filters as { title, key, values }}
          <TypeSelector {title} {key} {values} />
          {/each}
        </div>
      </div>

      <div class="wrap" style={$isDesktop ? `margin-top:-${filtersHeight}px` : '' }>
        {#each list as item}
        <ItemCard {item} {tags} />
        {/each}
      </div>

    </div>
  </div>

</div>
