<script>
import { fade } from 'svelte/transition'
import { debug, orders } from '../stores.js'
import Orders from './Orders.svelte'

export let active = false
let expanded = false
const toggle = () => {
  expanded = !expanded
}

let bar
const animate = () => {
  bar && bar.animate && bar.animate([
    { backgroundColor: 'rgba(201,45,46,0.5)' },
    { backgroundColor: '#FFF' }
  ], {
    duration: 1200
  })
}

let total
$: {
  total = $orders.reduce((acc, cur) => acc + cur.amount, 0).toFixed(2)
  animate()
}

</script>

<style>
.checkout {
  left: 50%;
  height: 85vh;
  transform: translate3d(-50%,100%,0);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  transition: transform 250ms ease-in-out;
}
.checkout.active {
  transform: translate3d(-50%,calc(100% - 4rem),0);
}
.checkout.expanded {
  transform: translate3d(-50%,0,0);
}
</style>

{#if expanded}
<div class="overlay fixed inset-0 bg-black opacity-50 z-10" transition:fade on:click={toggle}></div>
{/if}

<div
  class="checkout fixed bottom-0 mx-auto w-full max-w-screen-md flex flex-col bg-white border border-gray-300 shadow-md cursor-pointer z-20 overflow-hidden"
  class:active class:expanded transition:fade>

  <div class="h-16 flex flex-col border-b border-gray-300 px-8 pb-1" bind:this={bar}>
    <div class="h-1 w-64 bg-gray-600 self-center"></div>
    <div class="flex-1 flex flex-row items-center" on:click={toggle}>
      <div class="text-2xl font-bold text-gray-700 tracking-wide">${total.slice(0, total.length - 3)}<span class="text-base">{total.slice(-3)}</span></div>
      <div class="flex-1 text-sm text-center">{$debug}</div>
      <div class="flex items-center justify-center">
        <a href="#" class="btn dark" on:click|preventDefault|stopPropagation={animate}>HAMMER IT IN</a>
      </div>
    </div>
  </div>

  {#if $orders.length === 0}
  <div class="w-full h-full flex items-center justify-center">
    <p class="text-xl font-bold text-gray-400">No orders yet!</p>
  </div>
  {:else}
  <div class="w-full h-full overflow-x-hidden overflow-y-auto pt-4 px-4">
    <Orders />
  </div>
  {/if}

</div>
