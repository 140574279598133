<script>
import { types } from '../stores.js'

export let title
export let key
export let values

const attrs = values.split(',')

const handleClick = attr => {
  $types[key] = $types[key] === attr ? '' : attr
}
</script>

<style>
.item {
  min-width: 6rem;
}
.item.active {
  color: #C92D2E;
  border-color: #C92D2E;
}
</style>

<div class="w-full flex flex-col bg-white shadow mb-8">
  <div class="bg-brand-red font-bold text-sm text-white text-center px-2 py-2">{title}</div>
  <div class="flex flex-row flex-wrap items-center justify-around pt-4">
    {#each attrs as attr}
    <button
      class="item h-10 flex items-center justify-center border-2 border-gray-400 text-gray-400 text-xs tracking-wide leading-tight font-bold rounded-full cursor-pointer md:hover:bg-red-100 focus:outline-none md:focus:outline-shadow px-2 mb-4"
      class:active={$types[key] === attr}
      on:click={() => handleClick(attr)}
      >{attr}</button>
    {/each}
  </div>
</div>
