<script>
import { onDestroy } from 'svelte'
import { pages, checkout, items, orders } from './stores.js'
import { SvelteToast, toast } from '@zerodevx/svelte-toast'
import Navaid from 'navaid'
import Header from './components/Header.svelte'
import CheckoutBar from './components/CheckoutBar.svelte'
import ErrorPage from './pages/ErrorPage.svelte'
import Book from './pages/Book.svelte'
import Features from './pages/Features.svelte'
import ItemList from './pages/ItemList.svelte'
import ItemDetails from './shop/ItemDetails.svelte'

window.toast = toast

// Setup Router
let component
let props
const run = async (thunk, params) => {
  $pages = [location.pathname, ...$pages]
  props = params || {}
  component = thunk
}
const selectItem = (cat, handle, props) => {
  const item = $items.find(i => i.cat === cat && i.handle === handle)
  if (!item) {
    run(ErrorPage)
    return
  }
  // Check if item exist inside order
  const found = $orders.find(i => i.uid === item.uid)
  run(ItemDetails, {
    item: item,
    quantity: found ? found.quantity : 0,
    ...props
  })
}
const router = Navaid('/')
  // Marketing Pages
  .on('/features', () => run(Features))
  // Electrical
  .on('/app/electrical', () => run(ItemList, {
    cat: 'electrical',
    items: $items.filter(i => i.cat === 'electrical'),
    title: 'Electrical Works',
    filters: [
      { title: `What's your housing type?`, key: 'housingType', values: 'HDB,CONDO,LANDED' }
    ],
    tags: ['housingType']
  }))
  .on('/app/electrical/:handle', params => selectItem('electrical', params.handle, {
    types: [
      { name: 'HOUSING TYPE', key: 'housingType' }
    ]
  }))
  // Painting
  .on('/app/painting', () => run(ItemList, {
    cat: 'painting',
    items: $items.filter(i => i.cat === 'painting'),
    title: 'Painting',
    filters: [
      { title: `What's your housing type?`, key: 'housingType', values: 'HDB,CONDO,LANDED' },
      { title: 'What type of paint do you like?', key: 'paintType', values: 'V5000,PREMIUM,SEALER' }
    ],
    tags: ['housingType', 'paintType']
  }))
  .on('/app/painting/:handle', params => selectItem('painting', params.handle, {
    types: [
      { name: 'HOUSING TYPE', key: 'housingType' },
      { name: 'PAINT TYPE', key: 'paintType' }
    ]
  }))
  // Hacking
  .on('/app/hacking', () => run(ItemList, {
    cat: 'hacking',
    items: $items.filter(i => i.cat === 'hacking'),
    title: 'Hacking & Haulage',
    filters: [
      { title: `What's your housing type?`, key: 'housingType', values: 'HDB,CONDO,LANDED' },
      { title: 'How many rooms?', key: 'roomType', values: '3-ROOM,4-ROOM,5-ROOM,EXECUTIVE,MAISONETTE,ALA-CARTE' }
    ],
    tags: ['housingType', 'roomType']
  }))
  .on('/app/hacking/:handle', params => selectItem('hacking', params.handle, {
    types: [
      { name: 'HOUSING TYPE', key: 'housingType' },
      { name: 'ROOM TYPE', key: 'roomType' }
    ]
  }))
  // Tiling
  .on('/app/tiling', () => run(ItemList, {
    cat: 'tiling',
    items: $items.filter(i => i.cat === 'tiling'),
    title: 'Tiling & Masonry',
    filters: [
      { title: `What's your housing type?`, key: 'housingType', values: 'HDB,CONDO,LANDED' },
      { title: 'How many rooms?', key: 'roomType', values: '2-ROOM,3-ROOM,4-ROOM,5-ROOM,ADD-ON' }
    ],
    tags: ['housingType', 'roomType']
  }))
  .on('/app/tiling/:handle', params => selectItem('tiling', params.handle, {
    types: [
      { name: 'HOUSING TYPE', key: 'housingType' },
      { name: 'ROOM TYPE', key: 'roomType' }
    ]
  }))
  // Vinyl
  .on('/app/vinyl', () => run(ItemList, {
    cat: 'vinyl',
    items: $items.filter(i => i.cat === 'vinyl'),
    title: 'Vinyl Works',
    filters: [
      { title: `What brand would you like?`, key: 'brandType', values: 'EVORICH - ENGIN. VINYL,EVORICH - CONECTO,FLOOR XPERT - AMBIENT CLICK,FLOOR XPERT - BALANCE CLICK,FLOOR XPERT - PULSE CLICK,FLOOR XPERT - LOC' },
      { title: `What's your preferred thickness?`, key: 'thicknessType', values: '6MM,4MM,4.5MM,4.2MM' }
    ],
    tags: ['brandType', 'thicknessType']
  }))
  .on('/app/vinyl/:handle', params => selectItem('vinyl', params.handle, {
    types: [
      { name: 'BRAND', key: 'brandType' },
      { name: 'THICKNESS', key: 'thicknessType' }
    ]
  }))

  .on('/', () => run(Book))
  .on('*', () => run(ErrorPage))

// Load items
fetch('/items.json')
  .then(resp => {
    if (!resp.ok) {
      throw Error(resp.statusText)
    }
    return resp.json()
  })
  .then(data => {
    $items = data
    Zdx.spinner()
    router.listen()
  })
  .catch(err => {
    console.log(err)
  })

onDestroy(router.unlisten)
// TODO: Add dynamic meta tags


</script>

<main class="absolute inset-x-0 bottom-0 overflow-hidden">
  <svelte:component this={component} {...props} />
</main>

<Header />

<CheckoutBar active={$checkout} />

<SvelteToast options={{ reversed: true, intro: { y: 192 } }} />
