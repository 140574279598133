<script>
import { items, orders, pages, width, checkout } from '../stores.js'
import { fade, fly } from 'svelte/transition'
import { onMount } from 'svelte'
import Image from '../components/Image.svelte'
import QuantitySelector from '../components/QuantitySelector.svelte'

export let item
export let types

export let quantity = 0

const addItem = () => {
  $orders = [...$orders, {
    quantity: quantity,
    amount: quantity * item.unitPrice,
    ...item
  }]
}

const computeTransition = (node, params) => {
  if ($pages[1] === `/app/${item.cat}` || $pages[0] === `/app/${item.cat}`) {
    return fly(node, { x: $width, opacity: 1})
  }
  return fade(node, params)
}

onMount(() => {
  $checkout = true
})

</script>


<div class="page" in:computeTransition out:computeTransition>

  <div class="container mb-24">
    <a
      href={$pages[1] || '/'} class="w-24 h-12 flex items-center text-sm text-gray-900 hover:text-red-800 pl-4"
      ><span class="material-icons text-3xl">keyboard_arrow_left</span> BACK</a>

    <h1 class="text-center font-serif text-xl mt-2 mb-8">{item.title}</h1>

    <div class="w-full flex flex-col md:flex-row md:justify-around">

      <div class="w-full md:w-5/12 shadow">
        <div class="w-full pb-full relative">
          <div class="absolute w-full h-full">
            <Image
              src="https://source.unsplash.com/CMoxzoMsUfE/480x480"
              placeholder="https://source.unsplash.com/CMoxzoMsUfE/20x20"
              />
          </div>
        </div>
      </div>

      <div class="w-full md:w-5/12">
        <div class="w-full h-20 flex flex-row justify-between mt-6 px-6">
          <div class="flex flex-col">
            <div class="w-full text-xs font-bold text-gray-500">UNIT PRICE</div>
            <p class="h-full flex items-center text-2xl">${item.unitPrice === undefined ? '' : item.unitPrice.toFixed(2)}</p>
          </div>
          <div class="flex flex-col">
            <div class="w-full text-xs font-bold text-gray-500">UOM</div>
            <p class="h-full flex items-center text-base">{item.uom}</p>
          </div>
          <div class="flex flex-col">
            <div class="w-full text-xs font-bold text-center text-gray-500">QUANTITY</div>
            <div class="h-full flex items-center">
              <QuantitySelector bind:quantity={quantity} uom={item.uom} />
            </div>
          </div>
        </div>

        <!-- Order Button -->
        <div class="w-full px-6 mt-8">
          <button
            class="w-full btn dark h-12"
            class:disabled={quantity === 0}
            on:click={addItem}
            >${(quantity * item.unitPrice).toFixed(2)} - HAMMER THIS</button>
        </div>

        <!-- Short desc -->
        <div class="w-full flex flex-col px-6 mt-4">
          <div class="w-full text-xs font-bold text-gray-500 mt-4 mb-2">DESCRIPTION</div>
          <p class="w-full">{item.shortDesc}</p>
        </div>

        <!-- Types -->
        <div class="w-full flex flex-row justify-between px-6 mt-8">
          {#each types as type}
          <div class="flex flex-col">
            <div class="text-xs font-bold text-gray-500 mb-2">{type.name}</div>
            <p class="h-full flex items-center text-base">{item[type.key]}</p>
          </div>
          {/each}
        </div>

        <!-- Long desc -->
        <div class="w-full flex flex-col px-6 mt-4">
          <div class="w-full text-xs font-bold text-gray-500 mt-4 mb-2">DETAILS</div>
          <p class="w-full">{item.desc}</p>
        </div>


      </div>
    </div>

  </div>
</div>
